<template>
  <v-dialog
    v-model="dialog"
    transition="dialog-bottom-transition"
    :max-width="dialogWidth"
    scrollable
  >
    <v-card>
      <v-card-title class="pa-0">
        <v-toolbar
        >
          <v-btn
            icon
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            {{ $t('app.buttons.add') }} {{ $t('app.usuarios-sistema.single') }}
          </v-toolbar-title>
          <v-spacer />
          <v-btn
            rounded
            color="primary"
            @click="saveItem"
          >
            {{ $t('app.buttons.save') }}
          </v-btn>
        </v-toolbar>
      </v-card-title>
      <v-card-text>
        <validation-observer
          ref="observer"
          v-slot="{ validate, reset }"
        >
          <form>
            <v-list
              three-line
            >
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{ $t('app.roles.name') }}</v-list-item-title>
                  <v-list-item-subtitle>
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('app.roles.name')"
                      rules="required"
                    >
                      <v-autocomplete
                        v-model="item.roles"
                        :placeholder="$t('app.roles.name')"
                        :items="rolesPermitidos"
                        item-text="name"
                        item-value="id"
                        multiple
                        chips
                        deletable-chips
                        clearable
                        :error-messages="errors"
                        required
                      ></v-autocomplete>
                    </validation-provider>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{ $t('app.sucursales.name') }}</v-list-item-title>
                  <v-list-item-subtitle>
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('app.sucursales.name')"
                      rules="required"
                    >
                      <v-autocomplete
                        v-model="item.sucursales"
                        :placeholder="$t('app.sucursales.name')"
                        :items="sucursales"
                        item-text="nombre"
                        item-value="id"
                        :error-messages="errors"
                        multiple
                        chips
                        deletable-chips
                        clearable
                        required
                      ></v-autocomplete>
                    </validation-provider>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{ $t('app.headers.name') }}</v-list-item-title>
                  <v-list-item-subtitle>
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('app.headers.name')"
                      rules="required"
                    >
                      <v-text-field
                        v-model="item.nombre"
                        :placeholder="$t('app.headers.name')"
                        :error-messages="errors"
                        required
                      />
                    </validation-provider>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{ $t('app.headers.last_name') }}</v-list-item-title>
                  <v-list-item-subtitle>
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('app.headers.last_name')"
                      rules="required"
                    >
                      <v-text-field
                        v-model="item.apellido"
                        :placeholder="$t('app.headers.last_name')"
                        required
                        :error-messages="errors"
                      />
                    </validation-provider>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{ $t('app.headers.email') }}</v-list-item-title>
                  <v-list-item-subtitle>
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('app.headers.email')"
                      rules="required|email"
                    >
                      <v-text-field
                        v-model="item.email"
                        type="email"
                        :placeholder="$t('app.headers.email')"
                        required
                        :error-messages="errors"
                      />
                    </validation-provider>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{ $t('app.headers.password') }}</v-list-item-title>
                  <v-list-item-subtitle>
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('app.headers.password')"
                      rules="required"
                      vid="password"
                    >
                      <v-text-field
                        v-model="item.password"
                        type="password"
                        :placeholder="$t('app.headers.password')"
                        :error-messages="errors"
                        required
                      />
                    </validation-provider>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{ $t('app.headers.password_confirmation') }}</v-list-item-title>
                  <v-list-item-subtitle>
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('app.headers.password')"
                      rules="required|confirmed:password"
                    >
                      <v-text-field
                        v-model="item.password_confirmation"
                        type="password"
                        :placeholder="$t('app.headers.password_confirmation')"
                        :error-messages="errors"
                        required
                      />
                    </validation-provider>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{ $t('app.headers.street') }}</v-list-item-title>
                  <v-list-item-subtitle>
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('app.headers.street')"
                      rules="required"
                    >
                      <v-text-field
                        v-model="item.calle"
                        :placeholder="$t('app.headers.street')"
                        :error-messages="errors"
                        required
                      />
                    </validation-provider>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{ $t('app.headers.suburb') }}</v-list-item-title>
                  <v-list-item-subtitle>
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('app.headers.suburb')"
                      rules="required"
                    >
                      <v-text-field
                        v-model="item.colonia"
                        :placeholder="$t('app.headers.suburb')"
                        :error-messages="errors"
                        required
                      />
                    </validation-provider>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{ $t('app.headers.state') }}</v-list-item-title>
                  <v-list-item-subtitle>
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('app.headers.state')"
                      rules="required"
                    >
                      <v-autocomplete
                        v-model="item.estado_id"
                        :placeholder="$t('app.headers.state')"
                        :items="estados"
                        item-text="nombre"
                        item-value="id"
                        @change="getMunicipiosByState"
                        :error-messages="errors"
                        required
                      ></v-autocomplete>
                    </validation-provider>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{ $t('app.headers.municipality') }}</v-list-item-title>
                  <v-list-item-subtitle>
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('app.headers.municipality')"
                      rules="required"
                    >
                      <v-autocomplete
                        v-model="item.municipio_id"
                        :placeholder="$t('app.headers.municipality')"
                        :items="municipios"
                        item-text="nombre"
                        item-value="id"
                        @change="getLocalidadesByMunicipality"
                        :disabled="!municipios"
                        :error-messages="errors"
                        required
                        ref="fmunicipality"
                      ></v-autocomplete>
                    </validation-provider>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{ $t('app.headers.location') }}</v-list-item-title>
                  <v-list-item-subtitle>
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('app.headers.location')"
                      rules="required"
                    >
                      <v-autocomplete
                        v-model="item.localidad_id"
                        :placeholder="$t('app.headers.location')"
                        :items="localidades"
                        item-text="nombre"
                        item-value="id"
                        :disabled="!localidades"
                        :error-messages="errors"
                        required
                        ref="flocation"
                      ></v-autocomplete>
                    </validation-provider>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ $t('app.headers.borndate') }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    <v-row>
                      <v-col>
                        <validation-provider
                          v-slot="{ errors }"
                          :name="$t('app.headers.year')"
                          rules="required"
                        >
                          <v-autocomplete
                            :placeholder="$t('app.headers.year')"
                            :items="years"
                            v-model="borndate.year"
                            :error-messages="errors"
                            required
                          ></v-autocomplete>
                        </validation-provider>
                      </v-col>
                      <v-col>
                        <validation-provider
                          v-slot="{ errors }"
                          :name="$t('app.headers.month')"
                          rules="required"
                        >
                          <v-autocomplete
                            :placeholder="$t('app.headers.month')"
                            :items="months"
                            v-model="borndate.month"
                            :error-messages="errors"
                            required
                          ></v-autocomplete>
                        </validation-provider>
                      </v-col>
                      <v-col>
                        <validation-provider
                          v-slot="{ errors }"
                          :name="$t('app.headers.day')"
                          rules="required"
                        >
                          <v-autocomplete
                            :placeholder="$t('app.headers.day')"
                            :items="days"
                            v-model="borndate.day"
                            :error-messages="errors"
                            required
                          ></v-autocomplete>
                        </validation-provider>
                      </v-col>
                    </v-row>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{ $t('app.headers.avatar') }}</v-list-item-title>
                  <v-list-item-subtitle>
                    <base-file-input
                      :placeholder="$t('app.headers.avatar')"
                      icon="mdi-image"
                      accept="image/*"
                      @change="onChangeControl"
                    />
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </form>
        </validation-observer>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
  import {mapState} from 'vuex'

    export default {
        name: "UsuariosAdd",
      data () {
        return {
          dialog: false,
          item: {
            email: null,
            password: null,
            password_confirmation: null,
            avatar: null,
            foto: null,
            nombre: null,
            apellido: null,
            calle: null,
            colonia: null,
            localidad_id: null,
            municipio_id: null,
            estado_id: null,
            fecha_nacimiento: null,
            roles: [],
            sucursales: []
          },
          estados: [],
          municipios: [],
          localidades: [],
          sucursales: [],
          rolesPermitidos: [],
          datePickerModalStart: false,
          months: [],
          years: [],
          borndate: {
            day: null,
            month: null,
            year: null
          }
        }
      },
      computed: {
        ...mapState(['roles']),
        days () {
          let days = []
          if (this.borndate.year && this.borndate.month) {
            for(let day = 1; day <= this.$moment(`${this.borndate.year}-${this.borndate.month}`, "YYYY-MM").daysInMonth(); day++)
            {
              days.push({
                text: day,
                value: day < 10 ? `0${day}` : `${day}`
              })
            }
          }
          return days
        }
      },
      mounted() {
        EventBus.$on('usuarios-add', async () => {
          this.toggleLoader()
          this.estados = await this.getStates()
          this.sucursales = await this.getBranches()

          let roles = await this.getRoles(this.$store.state.token)
          this.rolesPermitidos = roles.filter(role => !['referee', 'player'].includes(role.name))

          for(let index = 0; index < 12; index++) {
            this.months.push({
              text: this.$moment().month(index).format('MMMM'),
              value: this.$moment().month(index).format('MM')
            })
          }

          for(let year = this.$moment().subtract(100, 'years').format('YYYY'); year <= this.$moment().subtract(4, 'years').format('YYYY'); year++) {
            this.years.push(`${year}`)
          }

          this.toggleLoader()
          this.dialog = true
        })
      },
      methods: {
        onChangeControl (files) {
          if (files && files.length > 0) {
            this.item.avatar = files[0]
          }
        },
        async getMunicipiosByState () {
            this.toggleLoader()
            this.municipios = await this.getMunicipalityByEstado(this.item.estado_id)
            this.$refs.fmunicipality.focus()
            this.toggleLoader()
          },
        async getLocalidadesByMunicipality () {
          this.toggleLoader()
          this.localidades = await this.getLocationsByMunicipality(this.item.municipio_id)
          this.$refs.flocation.focus()
          this.toggleLoader()
        },
          async saveItem () {
            this.$refs.observer.validate()
              .then(async success => {
                if (!success) return

                this.toggleLoader()

                this.item.fecha_nacimiento = [
                  this.borndate.year,
                  this.borndate.month,
                  this.borndate.day
                ].join('-')

                let form = new FormData()
                form.append('email', this.item.email)
                form.append('password', this.item.password)
                form.append('password_confirmation', this.item.password_confirmation)
                form.append('nombre', this.item.nombre)
                form.append('apellido', this.item.apellido)
                form.append('calle', this.item.calle)
                form.append('colonia', this.item.colonia)
                form.append('localidad_id', this.item.localidad_id)
                form.append('municipio_id', this.item.municipio_id)
                form.append('estado_id', this.item.estado_id)
                form.append('fecha_nacimiento', this.item.fecha_nacimiento)

                for (let role of this.item.roles) {
                  form.append('roles[]', role)
                }

                for (let sucursal of this.item.sucursales) {
                  form.append('sucursales[]', sucursal)
                }

                if (this.item.avatar) form.append('avatar', this.item.avatar)
                if (this.item.foto) form.append('foto', this.item.foto)

                await this.$http.post(route('v1/user'), form, {
                  headers: {
                    'Content-Type': 'multipart/form-data'
                  }
                })
                .then(response => {
                  if (response.body.code === 200) {
                    this.processSuccess(response)
                    this.item = {
                      email: null,
                      password: null,
                      password_confirmation: null,
                      avatar: null,
                      foto: null,
                      nombre: null,
                      apellido: null,
                      calle: null,
                      colonia: null,
                      localidad_id: null,
                      municipio_id: null,
                      estado_id: null,
                      fecha_nacimiento: null,
                      roles: [],
                      sucursales: []
                    }

                    requestAnimationFrame(() => {
                      this.$refs.observer.reset()
                    })

                    this.dialog = false
                    EventBus.$emit('reload-items')
                  } else {
                    this.processError(response)
                  }
                }, error => {
                  this.processError(error)
                })

                this.toggleLoader()
              })
          },
      }
    }
</script>

<style scoped>

</style>
